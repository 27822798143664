import React, { useEffect, useState } from 'react';
import "./style.css";

interface FullScreenLoaderProps {
    isLoading: boolean;
    message?: string;
}

const FullScreenLoader: React.FC<FullScreenLoaderProps> = ({ isLoading, message = "Cargando..." }) => {
    const [visible, setVisible] = useState(isLoading);

    useEffect(() => {
        if (isLoading) {
            setVisible(true);
        } else {
            const timer = setTimeout(() => setVisible(false), 250); // Coincide con la duración de la transición
            return () => clearTimeout(timer);
        }
    }, [isLoading]);

    if (!visible) return null;

    return (
        <div
            className={`fullscreen-loader fixed inset-0 z-50 flex flex-row gap-4 items-center justify-center bg-black bg-opacity-80 ${
                isLoading ? 'fullscreen-loader-enter' : 'fullscreen-loader-leave'
            }`}
        >
            <span className="loader"></span>
            <div className="flex flex-row items-center justify-center gap-4">
                <p className="text-white text-lg font-regular min-w-fit font-bookerly-regular">{message}</p>
            </div>
        </div>
    );
};

export default FullScreenLoader;
