/* eslint-disable @typescript-eslint/no-explicit-any */

import React from 'react';

import { useCallback, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';



import logoApp from '@assets/logo.png';

import { LockIcon, UserIcon } from '@components/AppIcons';
import useSwal from '@hooks/useSwal';
import useSessionApiService from '@services/api/useSessionApiService';
import FormDataAPI from '@models/form.data.api.model';
import LoginModel from '@models/login.model';
import useLocalStorage from '@hooks/useLocalStorage';
import LoginPayloadResponse from '@interfaces/LoginPayloadResponse';
import { useLoader } from '@context/FullScreenLoaderContext';
import LoginFormUIModel from '@models/login.form.ui.model';
import AuthContext from '@context/AuthContext';
import useInput from '@hooks/useInput';
import useCheckbox from '@hooks/useCheckbox';
import classJoiner from '@classes/ClassTools';
import InputBox from 'ui/CustomFormComponents/InputBox';
import CheckBox from 'ui/CustomFormComponents/CheckBox';
/**
 * Login properties interface
 */
interface LoginProperties {
    children?: React.ReactNode;
}

/**
 * Login component
 * @param {LoginProperties} props - Component props
 * @returns {JSX.Element}
 */
const Login: React.FC<LoginProperties> = (): JSX.Element => {
    const navigate = useNavigate();
    const { setAll }: any = useContext(AuthContext);

    const { getLS, setLS } = useLocalStorage();
    const { showLoading, hideLoading } = useLoader();
    const { alert, toast } = useSwal();
    const [user, userStatus, userAttributeObj, setUser, userFocus] = useInput('username', "text", "username", '');
    const [password, passwordStatus, passwordAttributeObj, setPassword, passwordFocus] = useInput('password', "password", '[A-Za-z0-9@$#_\\-&ñ]{6,20}', '');
    const [checkboxValue, setCheckbox, checkboxAttributeObj] = useCheckbox('remember', false);

    const { isLoading, login } = useSessionApiService();

    const logon = useCallback(() => {
        showLoading("Bienvenido!, un momento en lo que iniciamos tu sesión");
        // Asegúrate de que el token está en localStorage antes de redirigir
        setTimeout(() => {
            hideLoading();
            if (getLS("token")) {
                navigate("/my", { replace: true });
            }
        }, 250);
    }, [getLS, hideLoading, navigate, showLoading])

    const fetchLogin = useCallback(async () => {
        const properties = new FormDataAPI("user", new LoginModel(user, password));
        try {
            const { token, data, operationStatus, statusCode }: LoginPayloadResponse = await login(properties);
            if (operationStatus) {
                showLoading("Bienvenido!, un momento en lo que iniciamos tu sesión");
                setAll(token, data);
                setLS("token", token);
                if (checkboxValue) {
                    const payloadform = new LoginFormUIModel(user, password, checkboxValue);
                    setLS("loginform", payloadform);
                } else {
                    const payloadform = new LoginFormUIModel("", "", false);
                    setLS("loginform", payloadform);
                }
                logon();
                return;
            } else {
                if (statusCode === 401) {
                    hideLoading()
                    toast("error", "La información ingresada es incorrecta")
                } else {
                    hideLoading()
                    alert("error", "Ocurrio un error al procesar su solicitud")
                }
                return
            }
        } catch (error) {
            console.error("Error fetching ping:", error);  // Manejo del error¿
        }
    }, [alert, checkboxValue, hideLoading, login, logon, password, setAll, setLS, showLoading, toast, user]);

    useEffect(() => {
        userFocus()
    }, [userFocus])

    const submitForm = (e: any) => {
        e.preventDefault();
        if (isLoading) {
            return
        }
        if (userStatus && passwordStatus) {
            fetchLogin();
        } else {
            if (!userStatus) {
                toast("info", "Por favor verifique el campo de nombre de usuario!");
                userFocus();
                return
            } else if (!passwordStatus) {
                toast("info", "Por favor verifique el campo de contraseña!");
                passwordFocus();
                return
            }
        }
    }

    //Check if exist user value in localStorage
    useEffect(() => {
        const userTokenValue = getLS("token");
        if (userTokenValue && !isLoading) {
            showLoading("Un momento por favor, estamos iniciando tu sesión.")
            logon()
        }

        const formDataValues: any = getLS("loginform")
        if (formDataValues) {
            const { user, password, remember } = formDataValues;
            setUser(user);
            setPassword(password);
            setCheckbox(remember);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const firstBlockBreakPoints = "w-4/12 h-3/4 min-h-3/4 2xl:w-3/12";
    const secondBlockBreakPoints = "xs:w-full xs:h-full w-full h-full sm:w-full sm:h-full md:w-3/4 md:h-full md:backdrop-blur-md lg:w-4/12 lg:h-5/6 xl:w-4/12 xl:h-5/6 2xl:w-3/12 2xl:h-5/6";

    return <div className="flex w-screen h-screen overflow-hidden">
        <div className="BodyApp flex flex-1">
            <div className={classJoiner("signin flex flex-row items-center content-center justify-center h-screen w-screen overflow-hidden font-amazon-ember-regular")}>

                <div className={classJoiner(firstBlockBreakPoints, "backdrop-blur-md bg-white bg-opacity-10 rounded-sm hidden flex-col justify-center items-center shadow-md md:hidden lg:flex opacity-100")}>
                    <div className="flex flex-col text-white px-10 h-full justify-center">
                        <div className="mb-4 flex flex-col">
                            <div className="w-full flex gap-2 justify-center items-center">
                                <div className="w-1/4">
                                    <img className="w-full" alt="logo sia" src={logoApp} />
                                </div>
                            </div>
                            <div className="w-full flex flex-col justify-center items-center font-amazon-ember-regular mt-2">
                                <div className="text-lg md:text-sm lg:text-lg text-center"><p>Heimdall Attendance</p></div>
                                <div className="text-sm text-center"><span>Universidad Pedagógica Nacional Unidad 164 Zitácuaro</span></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classJoiner(secondBlockBreakPoints, "bg-gray-800 overflow-y-auto backdrop-blur-md bg-secondary-login bg-opacity-40 lg:bg-opacity-70 rounded-sm shadow-md text-white py-4 opacity-100 flex flex-col items-start justify-center")}>

                    <div className="content-center items-center hidden lg:flex lg:my-2">
                        <div className="w-12 h-12 ml-4 bg-upn-oficial flex justify-center items-center rounded-lg">
                            <UserIcon size={8} />
                        </div>
                        <div className="ml-4">
                            <h1 className="text-lg">Ingreso al sistema</h1>
                        </div>
                    </div>

                    <div className="md:mb-0 mb-4 w-full flex flex-col items-center justify-center mt-4 lg:hidden">
                        <div className="w-full flex justify-center items-center">
                            <div className="w-2/12">
                                <img className="w-full" alt="logo sia" src={logoApp} />
                            </div>
                        </div>
                        <div className="w-full flex flex-col justify-center items-center">
                            <div className="text-md md:text-sm lg:text-lg text-center"><p>Heimdall Attendance</p></div>
                            <div className="text-xs text-center"><span>Universidad Pedagógica Nacional Unidad 164 Zitácuaro</span></div>
                        </div>
                    </div>

                    <form className="w-full px-8 lg:px-10 h-full flex flex-col items-center justify-start" onSubmit={submitForm}>
                        <div className="form-block w-10/12 lg:w-11/12">

                            <div className="block mt-2 lg:mt-8">
                                <InputBox
                                    label="Usuario"
                                    helperText="Correo Electronico / Nombre de usuario  /Matricula / Telefono"
                                    hookProps={userAttributeObj}
                                    placeholder="Ingrese su usuario"

                                />
                            </div>

                            <div className="block mt-8">
                                <InputBox
                                    label="Contraseña"
                                    helperText="Contraseña/Solo numeros"
                                    hookProps={passwordAttributeObj}
                                    placeholder="Ingrese su contraseña"
                                />
                            </div>

                            <div className="flex flex-col gap-4 mt-2 mb-2 items-center justify-between">
                                <div className='flex items-center justify-end w-full'>
                                    <CheckBox hookProps={checkboxAttributeObj} label="Recordar usuario" />
                                </div>
                                <div className='flex flex-col items-center justify-center w-full mt-2'>
                                    <button color="primary" className="w-52 h-12 rounded-full flex flex-row items-center justify-center bg-green-700" type="submit">
                                        <span className="text-lg">Ingresar</span>
                                        <LockIcon size={5} classNameBefore={`text-white ml-2`} styleBefore={"stroke: #eee"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>;
};

export default Login;
