import React from 'react';
import SVGIcon from '@components/SVGIcon';
import { useSidebar } from '@context/SidebarContext';

interface ActionOption {
    action: () => void;
    label?: string;
    icon?: string;
    iconStyles?: any;
    className?: string;
    disabled?: boolean;
    selected?: boolean;
}

interface SidebarProps {
    logo?: string;
    noFixed?: boolean;
    logoClasses?: string;
    logoFormat?: 'image' | 'svg';
    position?: 'left' | 'right';
    actionButtons?: Array<ActionOption>;
}

const Sidebar: React.FC<SidebarProps> = ({
    logo,
    logoClasses,
    logoFormat = 'image',
    position = 'left'
}) => {
    const { actionButtons, isOpen }: { actionButtons: Array<ActionOption>, isOpen: boolean } = useSidebar();

    return (
        <div
            className={`${position === 'left' ? 'left-0' : 'right-0'} h-full bg-gray-800 text-white shadow-lg z-40 overflow-hidden`}
            style={{ width: isOpen ? '100px' : "0px", transition: 'width 0.3s ease, opacity 0.3s ease', opacity: isOpen ? 1 : 0 }}
        >
            <div className={["flex flex-col h-full",  isOpen ? 'opacity-100' : "opacity-0"].join(" ")}>
                {logo && (
                    <div className="p-4 flex items-center justify-center">
                        {logoFormat === 'svg' ? (
                            <SVGIcon src={logo} size={10} />
                        ) : (
                            <img src={logo} alt="Logo" className={["h-8", logoClasses].join(" ")} />
                        )}
                    </div>
                )}

                <div className={["flex flex-col", !logo && "pt-2"].join(" ")}>
                    {actionButtons.map((option, index) => (
                        <button
                            key={index}
                            onClick={!option.disabled ? option.action : undefined}
                            className={[
                                "w-full mb-8 flex flex-col items-center justify-center transition-colors duration-300 bg-transparent h-16 text-lightAlt hover:text-tangerineEmphasis",
                                option.selected && "bg-white",
                                option.disabled && 'opacity-50 cursor-not-allowed'
                            ].join(" ")}
                            disabled={option.disabled}
                        >
                            {option.icon && (
                                <SVGIcon
                                    size={9}
                                    src={option.icon}
                                    style={option.iconStyles}
                                    className="icon"
                                    classNameBefore={[option.selected && "text-tangerineEmphasis"].join(" ")}
                                    styleBefore={"fill: currentColor;"}
                                />
                            )}
                            <div className="optionLabelContainer w-10/12">
                                <p className={['font-amazon-ember-bold text-xs mt-2', option.selected && "text-selected"].join(" ")}>{option.label}</p>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
