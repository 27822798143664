/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect, useRef, useCallback } from 'react';

const expressions: any = {
    'username': '[a-zA-Z0-9@.-_-]{5,}',
    'numeric-password': '[0-9]{6,}',
    'password': '[A-Za-z0-9@$#_\\-&ñ]{6,20}'
}

/**
     * @function
     * @param {String} name 
     * @param {String} type 
     * @param {String | null} regexp
     * @param {any} initValue 
     * @returns Array: [value, status, attributeObj, setValue, focus, reset]
     */
const useInput = (name: string, type:string='text', regexp: string | null = null, initValue:any = ''): Array<any> => {
    const [value, setValue] = useState(initValue);
    const [touched, setTouched] = useState<string>('false');
    const [status, setStatus] = useState(false);
    const [regExp, setRegExp] = useState<any>(null);
    const inputRef = useRef<any>();

    useEffect(() => {        
        if(regexp && !regExp){
            if(Object.keys(expressions).includes(regexp)){
                setRegExp(new RegExp('^' + expressions[regexp] + '$'))  
            } else {
                setRegExp(new RegExp('^' + regexp + '$'))
            }
        }        
    }, [regexp, regExp])

    useEffect(() => {        
        if(regexp && regExp){
            if(regExp.test(value)){
                setStatus(true)
            } else {
                setStatus(false)
            }
        } else {
            setStatus(true)
        }
    }, [value, regexp, regExp])

    const setFocus = useCallback(() => {
        if(inputRef){
            inputRef.current.focus()
        }
    }, [])

    const setReset = ()  => setValue(initValue);
    const setClean = ()  => setValue('');
    const onChange = (e: any) => {
        setTouched('true');
        setValue(e.target.value)
    }

    const attributeObj = {
        id: name,
        name,
        onChange,
        ref: inputRef,
        status: status.toString(),
        touched,
        type,
        value        
    }

    return [value, status, attributeObj, setValue, setFocus, setClean, setReset]
}

export default useInput;