import React from 'react';
import { ReactSVG } from 'react-svg';

interface IconButtonProps {
    action?: () => void;
    imgSource?: string;
    className?: string;
    disabled?: boolean;
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'light' | 'dark';
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
}

const IconButton: React.FC<IconButtonProps> = ({
    action,
    imgSource,
    className,
    disabled,
    color = 'light',
    size = 'md',
}) => {
    const colorClasses = {
        primary: 'text-blue-500 hover:text-blue-700',
        secondary: 'text-gray-500 hover:text-gray-700',
        success: 'text-green-500 hover:text-green-700',
        error: 'text-red-500 hover:text-red-700',
        info: 'text-teal-500 hover:text-teal-700',
        warning: 'text-yellow-500 hover:text-yellow-700',
        light: 'text-gray-100 hover:text-gray-200',
        dark: 'text-gray-800 hover:text-gray-900',
    };

    const sizeClasses = {
        xs: 'h-4 w-4',
        sm: 'h-5 w-5',
        md: 'h-6 w-6',
        lg: 'h-8 w-8',
        xl: 'h-10 w-10',
        xxl: 'h-12 w-12',
    };

    return (
        <button
            className={`bg-transparent rounded ${sizeClasses[size]} flex items-center justify-center transition-colors duration-300 ${colorClasses[color]} ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={disabled == true || !action ? undefined : action}
        >
            {imgSource && (
                <ReactSVG
                    src={imgSource}
                    className="icon"
                    beforeInjection={(svg) => {
                        svg.setAttribute('style', `width: 100%; height: 100%; fill: currentColor;`);
                    }}
                />
            )}
        </button>
    );
};

export default IconButton;
