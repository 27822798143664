import AuthContext from "@context/AuthContext";
import useSwal from "@hooks/useSwal";
import LoginPayloadResponse from "@interfaces/LoginPayloadResponse";
import useAttendanceApiService from "@services/api/useAttendanceApiService";
import { useCallback, useContext, useEffect, useState } from "react";
import codes from "@constants/HttpStatusCodes";

const {
  OK,
  NOT_FOUND,
  BAD_REQUEST,
  CONFLICT,
  FORBIDDEN,
  UNAUTHORIZED
} = codes;

const months = [
  { value: 1, label: "Enero" },
  { value: 2, label: "Febrero" },
  { value: 3, label: "Marzo" },
  { value: 4, label: "Abril" },
  { value: 5, label: "Mayo" },
  { value: 6, label: "Junio" },
  { value: 7, label: "Julio" },
  { value: 8, label: "Agosto" },
  { value: 9, label: "Septiembre" },
  { value: 10, label: "Octubre" },
  { value: 11, label: "Noviembre" },
  { value: 12, label: "Diciembre" }
];

const periods = [
  { value: 1, label: "1-15" },
  { value: 2, label: "16-30/31" }
];

// Calcula años desde hace 2 años hasta 15 años en el futuro
const getYears = () => {
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 2;
  const endYear = currentYear + 15;
  const years = [];
  for (let year = startYear; year <= endYear; year++) {
    years.push(year);
  }
  return years;
};

const Report: React.FC<any> = () => {
  const [loadedStatus, setLoadedStatus] = useState<boolean>(false);
  const { isLoading, getCoworkers, getReport } = useAttendanceApiService();
  const { user, logoutAction }: any = useContext(AuthContext);
  const [coworkers, setCoworkers]: any = useState<any | undefined>(undefined);
  const [selectedCoworker, setSelectedCoworker] = useState<any>(null);
  const [report, setReport] = useState<any>(null); // Almacena el reporte obtenido
  const { alert, toast } = useSwal();

  const [month, setMonth] = useState<number>(new Date().getMonth() + 1); // Mes actual por defecto
  const [period, setPeriod] = useState<number>(1); // Periodo 1 por defecto
  const [year, setYear] = useState<number>(new Date().getFullYear()); // Año actual por defecto


  const handleLogon = useCallback(() => {
    setLoadedStatus(true);
    getCoworkers()
      .then((request: LoginPayloadResponse) => {
        const { data, statusCode } = request;

        if (statusCode === 200) {
          setCoworkers(data);
        } else if (statusCode === 401) {
          alert("warning", "Acceso denegado");
          logoutAction();
        } else {
          alert("error", "Ocurrió un error al solicitar su información");
          logoutAction();
        }
      })
      .catch((err: any) => {
        console.log(err);

        alert("error", "Ocurrió un error desconocido");
        logoutAction();
      });
  }, [alert, getCoworkers, logoutAction, setCoworkers]);

  useEffect(() => {
    if (user && !isLoading && !loadedStatus && coworkers === undefined) {
      handleLogon();
    }
  }, [handleLogon, isLoading, loadedStatus, user, coworkers]);

  // Función que se llama cuando se selecciona un coworker
  const getPersonalReport = (coworker: any) => {
    if(selectedCoworker && selectedCoworker.codebar === coworker.codebar){
      toast("info", "Ya esta cargado el reporte del trabajador", 500, true, "bottom-end")
      return
    }
    
    if (!isLoading) {
      setSelectedCoworker(coworker);
      getReport(coworker.codebar, month, period, year).then((response: any) => {
        const { statusCode, data } = response;

        if (statusCode === OK) {
          toast("success", "reporte obtenido con éxito", 500, true, "bottom-end");
          setReport(data)
          return
        }

        if ([BAD_REQUEST, FORBIDDEN, UNAUTHORIZED].includes(statusCode)) {
          alert("error", "Acceso denegado");
          setReport([]);
          logoutAction();
          return
        }

        if (CONFLICT == statusCode) {
          alert("warning", "Ocurrio un error en la solicitud");
          setReport([])
          return
        }


        if(NOT_FOUND == statusCode){
          alert("info", "No hay datos reportados del trabajador");
          setReport([])
          return
        }

        setReport([])
        alert("info", "No se pudo obtener el reporte del trabajador")
        return
      });
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      {/* Selectores */}
      <div className="mb-6 flex gap-6">
        <select
          className="border rounded-lg p-3 bg-white shadow-sm focus:outline-none focus:ring focus:border-blue-300"
          value={month}
          onChange={(e) => setMonth(Number(e.target.value))}
        >
          {months.map((m) => (
            <option key={m.value} value={m.value}>
              {m.label}
            </option>
          ))}
        </select>

        <select
          className="border rounded-lg p-3 bg-white shadow-sm focus:outline-none focus:ring focus:border-blue-300"
          value={period}
          onChange={(e) => setPeriod(Number(e.target.value))}
        >
          {periods.map((p) => (
            <option key={p.value} value={p.value}>
              {p.label}
            </option>
          ))}
        </select>

        <select
          className="border rounded-lg p-3 bg-white shadow-sm focus:outline-none focus:ring focus:border-blue-300"
          value={year}
          onChange={(e) => setYear(Number(e.target.value))}
        >
          {getYears().map((y) => (
            <option key={y} value={y}>
              {y}
            </option>
          ))}
        </select>
      </div>

      <div className="flex flex-row space-x-6 relative" style={{ height: "600px" }}>
        {/* Lista de coworkers */}
        <div className="w-1/3 bg-white rounded-lg shadow-md overflow-y-auto p-4">
          <ul>
            {coworkers &&
              coworkers.map((coworker: any, key: number) => (
                <li
                  key={coworker.codebar}
                  className="p-3 border-b cursor-pointer hover:bg-gray-100 transition duration-300"
                  onClick={() => getPersonalReport(coworker)}
                >
                  <span className="font-semibold">{key + 1}:</span> {coworker.nombre} {coworker.app} {coworker.apm}
                </li>
              ))}
          </ul>
        </div>

        {/* Sección de resultados */}
        <div className="w-2/3 bg-white rounded-lg shadow-md p-6 overflow-y-auto h-full">
          {selectedCoworker && (
            <div>
              <div className="text-xl font-bold mb-6 border-b pb-4 text-center">
                <p>Reporte de {selectedCoworker.nombre} {selectedCoworker.app} {selectedCoworker.apm}</p>
                {report && report.length >= 1 && (
                  <p className="text-blue-600 mt-2">
                    {period === 1 ? "Primera quincena" : "Segunda quincena"} de {months[month - 1].label} de {year}
                  </p>
                )}
              </div>

              {!isLoading && report && report.length > 0 ? (
                <table className="min-w-full bg-white border border-gray-300 rounded-md">
                  <thead>
                    <tr>
                      <th className="px-4 py-2 bg-gray-100 text-left text-sm font-semibold text-gray-600">
                        Día
                      </th>
                      <th className="px-4 py-2 bg-gray-100 text-left text-sm font-semibold text-gray-600">
                        Registros de asistencia
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {report.map((item: any, index: number) => (
                      <tr key={index} className="hover:bg-gray-100">
                        <td className="px-4 py-2 border-b text-sm text-gray-700">
                          {item.dia}
                        </td>
                        <td className="px-4 py-2 border-b text-sm text-gray-700">
                          <ul className="list-disc list-inside">
                            {item.registros.map((registro: string, regIndex: number) => (
                              <li key={regIndex} className="text-gray-700">
                                {registro}
                              </li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-gray-600 text-center">
                  No hay registros de asistencia para este trabajador en el periodo seleccionado.
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Report;
