import React, { useCallback, useContext, useEffect, useState } from 'react';
import Header from '@components/HeaderBar';
import { Outlet } from 'react-router-dom';
import Sidebar from '@components/Sidebar';
import { SidebarProvider } from '@context/SidebarContext';
import useSwal from '@hooks/useSwal';
import AuthContext from '@context/AuthContext';
import { useSessionApiService } from '@services/index';
import LoginPayloadResponse from '@interfaces/LoginPayloadResponse';
import logo from '@assets/logo.png';
import useAttendanceApiService from '@services/api/useAttendanceApiService';
import codes from "@constants/HttpStatusCodes";

const {
    BAD_REQUEST,
    CONFLICT,
    FORBIDDEN,
    UNAUTHORIZED
} = codes;


/**
 * Attendance properties interface
 */
interface AttendanceProperties {
    children?: React.ReactNode;
}

/**
 * Attendance component
 * @param {AttendanceProperties} props - Component props
 * @returns {JSX.Element}
 */
const Attendance = ({ }: AttendanceProperties): JSX.Element => {
    const [loadedStatus, setLoadedStatus] = useState<boolean>(false);
    const [authenticated, setAuthenticated] = useState<boolean>(false);
    const { isLoading, logon } = useSessionApiService();
    const { authHeimdallr } = useAttendanceApiService();
    const { setUser, user, logoutAction }: any = useContext(AuthContext);
    const {alert, question, toast} = useSwal();


    const handleLogon = useCallback(() => {
        setLoadedStatus(true)
        logon().then((request: LoginPayloadResponse) => {
            const { data, statusCode } = request;            
            if(statusCode===200){
                setUser(data.details);
            } else if(statusCode===401){
                alert("warning", "Su sesión ha caducado por favor ingrese nuevamente sus credenciales de acceso");
                logoutAction();
            } else {
                alert("error", "Ocurrio un error al solicitar su información");
                logoutAction();
            }
        }).catch((err: any) => {
            console.log(err);
            
            alert("error", "Ocurrio un error desconocido");
            logoutAction();
        })
    }, [alert, logon, logoutAction, setUser]);

    useEffect(() => {
        if (!user && !isLoading && !loadedStatus) {
            handleLogon();
        }
    }, [handleLogon, isLoading, loadedStatus, user]);

    useEffect(()=>{
        
        if(user && !isLoading && !authenticated) {
            setAuthenticated(true)
            authHeimdallr().then((result: any)=>{
                const {statusCode, operationStatus} = result;
                if(operationStatus){
                    return
                }

                if([BAD_REQUEST, FORBIDDEN, UNAUTHORIZED].includes(statusCode)){
                    alert("error", "Acceso denegado");
                    logoutAction();
                    return
                }

                if(CONFLICT == statusCode){
                    alert("warning", "Ocurrio un error en la solicitud");
                    logoutAction();
                    return
                }
                
            }).catch((error: any)=>{
                console.log("Unknown Error", error);
                alert("error", "Error desconocido");
                logoutAction();
            });
        }
    }, [user, isLoading, loadedStatus])

    const handleLogoutManager = () => {
        question("¿Esta seguro de cerrar su sesión y salir del sistema?").then(() => {
            logoutAction();
            toast("success", "Hasta luego!");
        })
    }

    const myActions: any = [
        {
            type: 'button',
            color: 'error',
            action: handleLogoutManager,
            label: "Cerrar Sesión"
        }
    ]

    return <SidebarProvider>
        <div className="Attendance flex flex-col w-full h-screen overflow-hidden bg-semiwhite">
            {/* Navigation Header */}
            <div className="NavigationHeader w-full">
                <Header logo={logo} title={"Heimdallr Attendance System 1.0"} actionButtons={myActions}/>
            </div>

            {/* Main Body with Sidebar and Content */}
            <div className="AppBody flex w-full h-full flex-grow overflow-hidden">
                {/* Sidebar: Full height and fixed width */}
                <div className="Sidebar h-full bg-gray-800">
                    <Sidebar />
                </div>

                {/* Main Content: Takes remaining width */}
                <div className="BodyContent flex-grow h-full overflow-auto bg-whites">
                    <Outlet />
                </div>
            </div>
        </div>
    </SidebarProvider>;
};

export default Attendance;
