import { ReactSVG } from 'react-svg';

interface ButtonProps {
    children?: React.ReactNode;
    className?: string;
    label?: string;
    imgSource?: string;
    imageSourceType?: string;
    action?: () => void;
    onClick?: () => void;
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'light' | 'dark';
    rounded?: boolean;
    disabled?: boolean;
    type?: 'button' | 'reset' | 'submit',
}

const Button: React.FC<ButtonProps> = ({
    children,
    className,
    label,
    imgSource,
    imageSourceType,
    action,
    onClick,
    color = 'primary',
    disabled,
    rounded,
    type = 'button'
}) => {
    const baseClasses = `h-fit flex font-bold py-2 px-6 ${rounded ? "rounded-full" : "rounded-sm"} transition-all duration-75`;
    const colorClasses = {
        primary: 'bg-tangerine hover:bg-tangerineEmphasis active:opacity-60 text-black',
        secondary: 'bg-blue-500 hover:bg-blue-700 active:opacity-60 text-white',
        success: 'bg-green-500 hover:bg-green-700 active:opacity-60 text-white',
        error: 'bg-red-500 hover:bg-red-700 active:opacity-60 text-white',
        info: 'bg-teal-500 hover:bg-teal-700 active:opacity-60 text-white',
        warning: 'bg-yellow-500 hover:bg-yellow-700 active:opacity-60 text-white',
        light: 'bg-gray-100 hover:bg-gray-200 active:opacity-60 text-black',
        dark: 'bg-gray-800 hover:bg-gray-900 active:opacity-60 text-white',
    };

    const handleAction = () => {
        if(disabled){
            return
        }
        if(onClick){
            onClick()
            return
        }
        if(action){
            action()
            return
        }
    }

    return (
        <button
            className={`${baseClasses} ${colorClasses[color]} ${className} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={handleAction}
            type={type}
        >
            {imgSource && (imageSourceType === "svg" ? <>
                <ReactSVG
                    src={imgSource}
                    className="icon"
                    beforeInjection={(svg) => {
                        svg.setAttribute('style', 'width: 24px; height: 24px;');
                    }}
                />
            </>: <img src={imgSource} alt="" className="inline-block mr-2" />)}
            {label && <p className="labelButton">{label}</p>}
            {!label && children}
        </button>
    );
};

export default Button;
