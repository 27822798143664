import FullScreenLoader from '@components/FullScreenLoader';
import React, { createContext, useContext, useState } from 'react';

interface LoaderContextProps {
    showLoading: (message?: string) => void;
    hideLoading: (message?: string) => void;
    isLoading: boolean;
}

const LoaderContext = createContext<LoaderContextProps | undefined>(undefined);

interface LoaderProviderProps {
    children: React.ReactNode
}

export const LoaderProvider: React.FC<LoaderProviderProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState("Cargando...");

    const showLoading = (msg: string = "Cargando...") => {
        setMessage(msg);
        setIsLoading(true);
    };

    const hideLoading = (msg: string = "") => {
        if(msg){
            setMessage(msg);
        }
        setIsLoading(false);
    };

    return (
        <LoaderContext.Provider value={{ showLoading, hideLoading, isLoading }}>
            <FullScreenLoader isLoading={isLoading} message={message} />
            {props.children}
        </LoaderContext.Provider>
    );
};

export const useLoader = () => {
    const context = useContext(LoaderContext);
    if (!context) {
        throw new Error("useLoader debe ser usado dentro de un LoaderProvider");
    }
    return context;
};
