/* eslint-disable @typescript-eslint/no-explicit-any */
import useLocalStorage from '@hooks/useLocalStorage';
import UserBasicInformation from '@models/user.basic.information.model';
import { createContext, useContext, useEffect, useState } from 'react';
import { Location, Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';

interface AuthContextType {
    auth: string | undefined;
    user: UserBasicInformation | undefined;
    setAuth: (token: string) => void;
    setUser: (user: UserBasicInformation) => void;
    setAll: any;
    logoutAction: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
    children?: React.ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const [auth, setAuth] = useState<string | undefined>(undefined);  // Inicializar con token vacío
    const [user, setUser] = useState<UserBasicInformation | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);  // Estado de carga
    const navigate = useNavigate();
    const location: Location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const { getLS, setLS } = useLocalStorage();

    const setAll = (token: string, user: UserBasicInformation) => {
        if(token){
            setAuth(token)
        }
        if(user){
            setUser(user)
        }
    }

    const logoutAction = () => {
        setAuth(undefined);
        setLS('token', "");
        setUser(undefined);
        navigate('/login', { state: { from: from }, replace: true });
    }

    useEffect(() => {
        const value: any = getLS('token');
        if (value) {
            setAuth(value);
        }
        setIsLoading(false);  // Marcar como terminado el proceso de carga
    }, [getLS]);

    return <AuthContext.Provider value={{ auth, user, setAuth, setUser, setAll, logoutAction }}>
        {isLoading ? <div>Cargando...</div> : props.children}  {/* Mostrar algo mientras se carga */}
    </AuthContext.Provider>
}


const ProtectedRoute = () => {
    const { auth }: any = useContext(AuthContext);    
    const location = useLocation();    
    // Si no hay autenticación, redirige al login
    if (!auth) {
        return <Navigate to="/login" replace state={{ from: location }} />;
    }

    return <Outlet />;
};

export {
    ProtectedRoute
}
export default AuthContext;
