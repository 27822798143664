import React, { createContext, useContext, useState } from 'react';

// Definimos el tipo de ActionOption
interface ActionOption {
    action: () => void;
    label?: string;
    icon?: string;
    iconStyles?: any;
    className?: string;
    disabled?: boolean;
    selected?: boolean;
}

// Definimos el contexto
interface SidebarContextProps {
    actionButtons: Array<ActionOption>;
    setActionButtons: React.Dispatch<React.SetStateAction<Array<ActionOption>>>;
    isOpen: boolean;
    toggleSidebar: ()=>void
}

const SidebarContext = createContext<SidebarContextProps | undefined>(undefined);

// Hook para usar el contexto
export const useSidebar = () => {
    const context = useContext(SidebarContext);
    if (!context) {
        throw new Error('useSidebar debe ser usado dentro de un SidebarProvider');
    }
    return context;
};

// Provider que encapsula la aplicación
export const SidebarProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [actionButtons, setActionButtons] = useState<Array<ActionOption>>([]);
    const [isOpen, setIsOpen] = useState(true);

    const toggleSidebar = () => {
        setIsOpen((current) => !current)
    }
    
    return (
        <SidebarContext.Provider value={{ actionButtons, setActionButtons, isOpen, toggleSidebar }}>
            {children}
        </SidebarContext.Provider>
    );
};
