import { AuthProvider, ProtectedRoute } from '@context/AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NotFound from '@pages/404.page';
import Login from '@pages/Login.page';
import Attendance from '@pages/Attendance';
import Report from '@pages/Attendance/Report';


function App() {
  return <div className="main fade-in bg-blue-900">
    <Router>
      <AuthProvider>
          <Routes>
            <Route path="login" element={<Login />} />{/*Ruta Login*/}
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<Attendance />}>
                <Route index element={<Report />} />{/*Mi perfil*/}
                <Route path="my" element={<Report />} />{/*Mi perfil*/}
              </Route>
            </Route>
            <Route path="*" element={<NotFound />} />{/*Acerca de este proyecto*/}
          </Routes>
      </AuthProvider>
    </Router>
  </div>;
}

export default App;
