import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';

import aws from '../../assets/aws-smile.png';
import searchIcon from '../../assets/icons/svg/search.svg';
import tileIcon from '../../assets/icons/svg/tile.svg';
import dropdownArrow from '../../assets/icons/svg/dropdown-arrow.svg';
import Button, { IconButton } from '@components/Button';

interface SecondaryNavRowProps {
    navigationOptions: NavItemProps[]
}

const SecondaryNavRow: React.FC<SecondaryNavRowProps> = ({ navigationOptions }) => {
    const [scrollIndex, setScrollIndex] = useState(0);
    const navRef = useRef<HTMLDivElement>(null);

    const handleScroll = (direction: 'left' | 'right') => {
        if (navRef.current) {
            const scrollAmount = navRef.current.offsetWidth;
            const maxScroll = navRef.current.scrollWidth - navRef.current.clientWidth;
            const newScrollIndex = direction === 'left'
                ? Math.max(scrollIndex - scrollAmount, 0)
                : Math.min(scrollIndex + scrollAmount, maxScroll);

            setScrollIndex(newScrollIndex);
        }
    };

    useEffect(() => {
        if (navRef.current) {
            navRef.current.scrollTo({ left: scrollIndex, behavior: 'smooth' });
        }
    }, [scrollIndex]);

    return (
        <div className="relative flex items-center">
            <button
                onClick={() => handleScroll('left')}
                className={`absolute left-0 px-4 py-2 bg-gray-700 text-white rounded-l ${scrollIndex <= 0 ? 'hidden' : ''}`}
            >
                &lt;
            </button>
            <div className="overflow-hidden w-full" ref={navRef}>
                <nav className="flex items-center">
                    <ul className="flex space-x-14 justify-center items-center px-10">
                        {navigationOptions.map((item, index) => createNavItem(item, index, "min-w-20 font-amazon-ember-regular text-xs"))}
                    </ul>
                </nav>
            </div>
            <button
                onClick={() => handleScroll('right')}
                className="absolute right-0 px-4 py-2 bg-gray-700 text-white rounded-r"
            >
                &gt;
            </button>
        </div>
    );
};


interface ActionButtonProps {
    type?: 'icon' | 'button' | 'link';
    color?: 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning' | 'light' | 'dark';
    action?: () => void; // Definir action como una función que no toma argumentos y no devuelve nada
    label?: string;
    imgSource?: string; // Definir imgSource como un string para la ruta de la imagen
    className?: string;
    disabled?: boolean;
    rounded?: boolean;
}

const createActionButton = (props: ActionButtonProps) => {    
    switch (props.type) {
        case "button":
            return <Button label={props.label} color={props.color} rounded={props.rounded} onClick={props?.action ? props.action : undefined}/>
        case "icon":
        default:
            return <IconButton action={props.action} imgSource={props.imgSource} className={props.className} disabled={props.disabled} />
    }
}

interface NavItemProps {
    target?: string;
    label?: string;
    icon?: string;
    type?: 'link' | 'dropdown';
    content?: NavItemProps[]; // Solo para dropdowns
}


const createNavItem = (props: NavItemProps, index: number, customLiClass?: string) => {
    if (props.type === 'dropdown' && props.content) {
        return (
            <li className={[
                "relative group hover:text-tangerine cursor-pointer outline-none bg-transparent decoration-transparent no-underline text-sm whitespace-nowrap",
                customLiClass
            ].join(" ")} key={index}>
                <div className="flex flex-row justify-center items-center p-0 m-0 min-w-22">
                    <Link to={props.target ? props.target : "#"} className=''>{props.label}</Link>
                    <ReactSVG
                        src={dropdownArrow}
                        className="w-full icon"
                        beforeInjection={(svg) => {
                            svg.setAttribute('style', 'width: 20px; height: 20px; font-weight: bold;');
                        }}
                    />
                </div>
                <ul className="absolute hidden group-hover:block bg-tangerineEmphasis text-contrast shadow-lg rounded mt-0 z-50">
                    {props.content.map((item, subIndex) => (
                        <li
                            className={[
                                "px-4 py-2 hover:text-white cursor-pointer outline-none bg-transparent decoration-transparent no-underline text-xs flex flex-row items-center justify-center whitespace-nowrap"
                            ].join(" ")} key={subIndex}>
                            {item.icon && (
                                <ReactSVG
                                    src={item.icon}
                                    className="w-full icon mr-1"
                                    beforeInjection={(svg) => {
                                        svg.setAttribute('style', 'width: 20px; height: 20px; font-weight: bold;');
                                    }}
                                />
                            )}
                            <Link to={item.target ? item.target : "/"}>{item.label}</Link>
                        </li>
                    ))}
                </ul>
            </li>
        );
    }

    return (
        <li
            className={[
                `transition-colors duration-300 hover:text-tangerine cursor-pointer outline-none bg-transparent decoration-transparent no-underline text-sm whitespace-nowrap`,
                customLiClass
            ].join(" ")} key={index}>
            <Link to={props.target ? props.target : "/"}>{props.label}</Link>
        </li>
    );
};

interface NavigationProperties {
    target?: string,
    label?: string
}

interface HeaderProps {
    logo?: string;
    title?: string;
    onTitleClick?: () => void;
    double?: boolean;
    doubleContentType?: 'node' | 'navigation';
    search?: (query: string) => void;
    searchInBox?: boolean;
    navigation?: Array<NavigationProperties>;
    secondNavigation?: Array<NavigationProperties>;
    actionButtons?: Array<ActionButtonProps>;
    children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
    logo,
    title,
    onTitleClick,
    double = false,
    doubleContentType = "node",
    search,
    searchInBox = true,
    navigation,
    actionButtons = [],
    children,
    secondNavigation
}) => {


    const handleSearchSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const query = (e.target as HTMLFormElement).elements.namedItem('search') as HTMLInputElement;
        if (search) {
            search(query.value);
        }
    };



    return (
        <header className="bg-gray-800 text-white p-4 w-full h-18 max-h-18">
            <div className="flex flex-col md:flex-row items-center justify-between w-full space-y-4 md:space-y-0 md:space-x-4">
                <div className="flex items-centter justify-center flex-grow-0 flex-shrink-0 min-w-0 p-0 m-0">
                    <img
                        src={logo || aws}
                        alt="Logo"
                        className="h-14 w-14 mr-3 border-0"
                        style={logo ? {} : { backgroundSize: '59px 35px', height: '35px', width: '59px' }}
                    />
                    {title && (
                        <>
                            {onTitleClick ? (
                                <div className="titleAction flex justify-center items-center p-0 m-0 transition-colors duration-300 hover:text-tangerine">
                                    <div className="icon w-8 h-8 mr-1">
                                        <ReactSVG
                                            src={tileIcon}
                                            className="w-full icon "
                                            beforeInjection={(svg) => {
                                                svg.setAttribute('style', 'width: 34px; height: 34px;');
                                            }}
                                        />
                                    </div>
                                    <h1 className="text-xl font-bold cursor-pointer" onClick={onTitleClick}>
                                        {title}
                                    </h1>
                                </div>
                            ) : (
                                <div className="titleAction flex justify-center items-center p-0 m-0">
                                    <h1 className="text-xl font-bold p-0 m-0">{title}</h1>
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className="flex items-center flex-grow flex-shrink basis-auto justify-start">
                    {search && (
                        <form onSubmit={handleSearchSubmit} className="flex items-center w-full max-w-md">
                            {searchInBox ? (
                                <div className="flex items-center bg-contrast hover:bg-slate-950 transition-all duration-800 rounded w-full" style={{ border: '#485156 1px solid' }}>
                                    <button type="submit" className="bg-transparent p-2 rounded w-10 h-10 flex justify-center items-center text-white hover:text-tangerine">
                                        <ReactSVG
                                            src={searchIcon}
                                            className="w-full icon"
                                            beforeInjection={(svg) => {
                                                svg.setAttribute('style', 'width: 28px; height: 28px;');
                                            }}
                                        />
                                    </button>
                                    <input
                                        type="text"
                                        name="search"
                                        className="bg-transparent text-white p-2 m-0 w-full h-10 rounded-sm border-none outline-none"
                                        placeholder="Buscar"
                                    />
                                </div>
                            ) : (
                                <div className="flex items-center w-full">
                                    <input
                                        type="text"
                                        name="search"
                                        className="bg-gray-700 text-white p-2 rounded flex-grow"
                                        placeholder="Search..."
                                    />
                                    <button type="submit" className="bg-blue-500 p-2 rounded ml-2">
                                        Search
                                    </button>
                                </div>
                            )}
                        </form>
                    )}
                </div>
                <div className="flex items-center flex-grow-0 flex-shrink-0 min-w-0 justify-end space-x-4">
                    {
                        navigation && <nav className="flex items-center">
                            <ul className="flex space-x-4">
                                {
                                    navigation.map((item: NavItemProps, index: number) => createNavItem(item, index))
                                }
                            </ul>
                        </nav>}
                    {
                        actionButtons && <div className="flex items-center">
                            {
                                actionButtons &&
                                actionButtons.map((actionButtonValue, index) => (
                                    <div key={index} className="ml-2">
                                        {createActionButton(actionButtonValue)}
                                    </div>
                                ))
                            }
                        </div>
                    }
                </div>
            </div>
            {double && (
                <div className="mt-4 relative w-full">
                    {
                        doubleContentType === "navigation" ? (secondNavigation && <SecondaryNavRow navigationOptions={secondNavigation} />)
                            :
                            <div className="w-full">
                                <div className="flex">
                                    {children}
                                </div>
                            </div>

                    }
                </div>
            )}
        </header>
    );
};

export default Header;
