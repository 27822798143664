import { forwardRef, useState } from 'react';
import './style.css'
import classJoiner from '@classes/ClassTools';

const InputBox = forwardRef((props: any, ref: any) => {

        const { autocomplete, className, label, hookProps, helperText, type, placeholder } = props        
        const [focus, setFocus] = useState(false);

        const onFocus = () => {
            setFocus(true);
        }
    
        const onBlur = () => {
            setFocus(false);
        }
    
    
        return <div className={["flex flex-col input mt-4", className].join(" ")}>
            <div className="input-box-container w-full input-effect">
                <input
                    autoComplete={autocomplete ? autocomplete : "off"}
                    className="input-style-1 w-full bg-transparent mt-1 py-2 px-2 pr-7 border-white border-b-2"
                    type={type ? type : 'text'}
                    placeholder={focus && placeholder ? placeholder : ''}
                    ref={ref}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    {...hookProps}
                />
                {
                    hookProps.touched === 'true' && hookProps.status === 'true' && <div className={classJoiner("ml-1 icon-status", focus ? 'pulse-animation' : '')}>
                        {/* <SvgCheck size={14} /> */}
                    </div>
                }
                {
                    hookProps.touched === 'true' && hookProps.status !== 'true' && <div className="ml-1 icon-status pulse-animation pt-1">
                        {/* <SvgMark className="info" size={focus ? 10 : 12} /> */}
                    </div>
                }
                {
                    label && <label htmlFor={hookProps.name} className={[" flex justify-start items-center cursor-text text-white", hookProps.value ? 'has-value' : ''].join(" ")}>
                        {label}
                    </label>
                }
                <span className="focus-border">
                    <i></i>
                </span>
            </div>
            <span className={["text-xs mt-1 text-center input-helper", focus ? 'input-helper-in' : ''].join(" ")}>{helperText ? helperText : ''}</span>
        </div>;

})

export default InputBox;