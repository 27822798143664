import { Link } from 'react-router-dom';
/**
 * NotFound component
 * @returns {JSX.Element}
 */
const NotFound = (): JSX.Element => {
    return (
        <div className="relative flex flex-col items-center justify-center h-screen overflow-hidden bg-gradient-to-r from-[#003399b6] to-[#6b46c1a9] text-white">
            {/* Mensaje de Error */}
            <div className="text-center z-10">
                <h1 className="text-6xl font-bold animate-pulse">404</h1>
                <p className="mt-4 text-2xl">Oops! Página no encontrada</p>
                <p className="mt-2 text-lg">
                    La página que estás buscando no existe o ha sido movida.
                </p>

                {/* Botón para regresar al inicio */}
                <Link
                    to="/"
                    className="mt-8 inline-block bg-white text-blue-600 px-6 py-3 rounded-full text-lg font-semibold transition-all hover:bg-blue-600 hover:text-white hover:shadow-lg"
                >
                    Volver al inicio
                </Link>
            </div>
        </div>
    );
};

export default NotFound;
