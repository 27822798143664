import {useState} from 'react';

const useCheckbox = (name: string, initialValue: boolean = false): [boolean, Function, any] => {
    const [value, setValue] = useState(initialValue);

    const toggle = (value: boolean) => {
        setValue(prevState => {
            return typeof value === 'boolean' ? value : !prevState;
        })
    }

    const attributeObj = {
        id: name,
        name,
        onChange: toggle,
        checked: value,
        type: "checkbox"
    }

    return [value, setValue, attributeObj]
}

export default useCheckbox;