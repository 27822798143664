import React from 'react';
import { ReactSVG } from 'react-svg';

interface SVGIconProps {
    src: string;
    size: number;
    style?: any;
    className?: string;
    classNameBefore?: string;
    styleBefore?: string;
}

const SVGIcon: React.FC<SVGIconProps> = ({
    src,
    style,
    size=12,
    className = "",
    classNameBefore = "",
    styleBefore = ""
}) => {
    return (
        <ReactSVG
            src={src}
            style={style}
            className={[className, `h-${size} w-${size}`].join(" ")}
            beforeInjection={(svg) => {
                svg.setAttribute('class', [classNameBefore, `h-${size} w-${size}`].join(" "));
                svg.setAttribute('style', styleBefore);
            }}
        />
    );
};

export default SVGIcon;
