class LoginFormUIModel{
    user?: string;
    password?: string;
    remember?: boolean;

    constructor(user?: string, password?: string, remember:boolean = false) {
        this.user = user;
        this.password = password;
        this.remember = remember || false;
    }
}

export default LoginFormUIModel;