/* eslint-disable @typescript-eslint/no-explicit-any */
import { isSuccessCode } from "@classes/HttpTools";
import { SERVER_PATHS } from "@config/constants";
import useRequest from "@hooks/useRequest";
import LoginPayloadResponse from "@interfaces/LoginPayloadResponse";
import ResponseObject from "@interfaces/ResponseObject";
import FormDataAPI from "@models/form.data.api.model";

const useSessionApiService = (currentEndPointPath: string = SERVER_PATHS.SHIELD_API) => {
    const { post, isLoading, hideLoading } = useRequest(currentEndPointPath);

    const login = async (userPayload: FormDataAPI): Promise<any> => {        
        const endpoint = `login`;        
        const result = await post(endpoint, {message: "Intentando ingresar a tu cuenta", autohide: true}, userPayload);
        const { data, message, statusCode }: ResponseObject = result.response;
        if ([200, 201].includes(statusCode)) {
            const payload:LoginPayloadResponse = {
                token: data.token || null,
                data: data.payload || null,
                statusCode: statusCode,
                message: message,
                operationStatus: isSuccessCode(statusCode)
            }
            return payload;
        } else {
            const payload:LoginPayloadResponse = {
                token: undefined,
                statusCode: statusCode,
                message: message,
                operationStatus: false
            }
            hideLoading()
            return payload;
        }
    }

    const logon = async () => {
        const endpoint = `logon`;        
        const result = await post(endpoint, {message: "Obteniendo tu información", autohide: true}, {});
        const { data, message, statusCode }: ResponseObject = result.response;
        if ([200, 201].includes(statusCode)) {
            const payload:LoginPayloadResponse = {
                data,
                token: data.token || null,
                statusCode: statusCode,
                message: message,
                operationStatus: isSuccessCode(statusCode)
            }
            return payload;
        } else {
            const payload:LoginPayloadResponse = {
                data: null,
                token: undefined,
                statusCode: statusCode,
                message: message,
                operationStatus: false
            }
            hideLoading()
            return payload;
        }
    }


    return {
        isLoading,
        login,
        logon
    }
}

export default useSessionApiService;