const classJoiner = (...args: Array<string | string[] | undefined | null | false>): string => {
    return args
        .flatMap(arg => 
            Array.isArray(arg) 
                ? arg.filter(Boolean)  // Si es un array, lo filtramos y lo mantenemos como está
                : arg ? [arg] : []     // Si es un string válido, lo convertimos en array para luego unirlo
        )
        .join(" ");  // Unir todas las clases válidas con un espacio
}

const testBreakPoints = "xs:bg-purple-600 bg-pink-500 sm:bg-red-500 md:bg-green-500 lg:bg-blue-500 xl:bg-yellow-500 2xl:bg-gray-500"

export {
    testBreakPoints
}
export default classJoiner;
