// src/constants/config.ts

// Constantes de rutas para las APIs de tu aplicación
export const SERVER_PATHS = Object.freeze({
    SIA_ROOT: 'https://api-sia.upn164.edu.mx/',
    API_HEIMDALLR: 'https://api-heimdallr.upn164.edu.mx/api',
    SHIELD_ROOT: 'https://api-shield.upn164.edu.mx/',
    SHIELD_API: 'https://api-shield.upn164.edu.mx/api/v1',
    ACADEMIC_API: 'https://api-academic-history.upn164.edu.mx/',
    // Puedes agregar más rutas de API aquí
});

// Constantes generales de la aplicación
export const APP_CONFIG = Object.freeze({
    APP_NAME: 'Sistema de Información Académica',
    VERSION: '2.0.1',
    SUPPORT_EMAIL: 'ingeniero.rene.corona@gmail.com',
    LOCALE: 'es-MX',
});
