/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLoader } from '@context/FullScreenLoaderContext';
import axios, { AxiosInstance } from 'axios';
import ApiResponseData from 'interfaces/ApiResponseData';
import useLocalStorage from './useLocalStorage';

const useRequest = (serverURL?: string) => {
    const { showLoading, hideLoading, isLoading } = useLoader();
    const { getLS } = useLocalStorage();
    // Obtiene la URL del servidor desde las variables de entorno
    const server: string = serverURL || import.meta.env.VITE_API_URL || '';

    const RequestHeaders: any = {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json'
    };

    const instance: AxiosInstance = axios.create({
        baseURL: server,
        headers: RequestHeaders
    });

    // Función para agregar encabezados adicionales como el token Bearer
    const getHeaderInstance = (extraHeaders: any = {}) => {
        const token = getLS('token');  // Recuperar el token del localStorage
        return {
            ...RequestHeaders,
            Authorization: token ? `Bearer ${token}` : undefined,  // Añadir el token Bearer si está presente
            ...extraHeaders
        };
    };

    const createInstance = (baseUrl: string, headers: any = {}) => {
        return axios.create({
            baseURL: baseUrl,
            headers
        });
    };

    const handleResponse = (response: any): ApiResponseData => {
        return {
            response: {
                message: response.statusText || '',
                data: response.data || null,
                statusCode: response.status,
                statusText: response.statusText,
            },
            request: {
                baseURL: response.config.baseURL,
                method: response.config.method,
                url: response.config.url
            }
        };
    };

    const handleError = (error: any): ApiResponseData => {
        return {
            response: {
                message: error.message || 'An error occurred',
                data: error.response?.data || null,
                statusCode: error.response?.status || 500,
                statusText: error.response?.statusText || 'Error',
            },
            request: {
                baseURL: error.config?.baseURL,
                method: error.config?.method,
                url: error.config?.url
            }
        };
    };

    // Funciones CRUD básicas
    const get = async (url: string, message: string | MessageObject = { message: "Procesando...", autohide: true, autoshow: true }, config: any = {}): Promise<ApiResponseData> => {
        let messageContent = "Procesando...";
        let shouldShowLoading: boolean = true;
        let shouldHideLoading: boolean = true;
    
        // Verificar si message es un string o un objeto
        if (typeof message === 'string') {
            messageContent = message;
        } else if (typeof message === 'object') {
            messageContent = message.message;
            shouldShowLoading = message.autoshow ?? true;
            shouldHideLoading = message.autohide ?? true;
        }

        try {
            // Mostrar loading si autoshow es true
            if (shouldShowLoading) {
                showLoading(messageContent);
            }

            // Incluir token en los headers
            // const tokenHeaders = {
            //     ...RequestHeaders,
            // };                     
            const tokenHeaders = getHeaderInstance(config.headers);                     
            const request = await instance.get(url, {
                ...config,
                headers: tokenHeaders  // Pasar los headers con el token Bearer
            });
    
            if (shouldHideLoading) {
                hideLoading();
            }

            return handleResponse(request);
        } catch (error) {
            if (shouldHideLoading) {
                hideLoading();
            }
            return handleError(error);
        }
    };

    interface MessageObject {
        message: string;
        autoshow?: boolean;
        autohide?: boolean;
    }
    
    const post = async (url: string, message: string | MessageObject = { message: "Procesando...", autohide: true, autoshow: true }, data: any, config: any = {}) => {
        let messageContent = "Procesando...";
        let shouldShowLoading: boolean = true;
        let shouldHideLoading: boolean = true;
    
        // Verificar si message es un string o un objeto
        if (typeof message === 'string') {
            messageContent = message;
        } else if (typeof message === 'object') {
            messageContent = message.message;
            shouldShowLoading = message.autoshow ?? true;
            shouldHideLoading = message.autohide ?? true;
        }
    
        try {
            // Mostrar loading si autoshow es true
            if (shouldShowLoading) {
                showLoading(messageContent);
            }
    
            // Incluir token en los headers
            const tokenHeaders = getHeaderInstance(config.headers);           
            // console.log("tokenHeaders", tokenHeaders);
             
            const response = await instance.post(url, data, {
                ...config,
                headers: tokenHeaders  // Pasar los headers con el token Bearer
            });
    
            if (shouldHideLoading) {
                hideLoading();
            }
    
            return handleResponse(response);
        } catch (error) {
            if (shouldHideLoading) {
                hideLoading();
            }
            return handleError(error);
        }
    };    

    const put = async (url: string, message:string="Procesando...", data: any, config = {}) => {
        try {
            showLoading(message);
            const response = await instance.put(url, data, config);
            hideLoading();
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const patch = async (url: string, message:string="Procesando...", data: any, config = {}) => {
        try {
            showLoading(message);
            const response = await instance.patch(url, data, config);
            hideLoading();
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    const deleteRequest = async (url: string, message:string="Procesando...", config = {}) => {
        try {
            showLoading(message);
            const response = await instance.delete(url, config);
            hideLoading();
            return handleResponse(response);
        } catch (error) {
            return handleError(error);
        }
    };

    return { createInstance, instance, getHeaderInstance, get, post, put, patch, deleteRequest, isLoading, showLoading, hideLoading };
};

export default useRequest;
