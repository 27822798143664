/* eslint-disable @typescript-eslint/no-explicit-any */
import { forwardRef } from 'react';

const CheckBox = forwardRef((props: any, ref: any) => {
    const { autocomplete, className, label, hookProps } = props
    
    return <div className={className}>
        <input
            autoComplete={autocomplete ? autocomplete : "off"}
            className="bg-transparent"
            ref={ref}
            {...hookProps}/> <label htmlFor={hookProps.name} className={hookProps && hookProps.checked ? '' : 'opacity-80'}>{label}</label>
    </div>;
})

export default CheckBox;