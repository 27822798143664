// import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import { ActionInProcessProvider } from 'context/ActionInProcessContext.tsx'
import { LoaderProvider } from '@context/FullScreenLoaderContext.tsx'

import './index.css'

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <LoaderProvider>
    <ActionInProcessProvider>
      <App />
    </ActionInProcessProvider>
  </LoaderProvider>
  // </React.StrictMode>,
)
