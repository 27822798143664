/* eslint-disable @typescript-eslint/no-explicit-any */
import { isSuccessCode } from "@classes/HttpTools";
import { SERVER_PATHS } from "@config/constants";
import useRequest from "@hooks/useRequest";
import LoginPayloadResponse from "@interfaces/LoginPayloadResponse";
import ResponseObject from "@interfaces/ResponseObject";

const useAttendanceApiService = (currentEndPointPath: string = SERVER_PATHS.API_HEIMDALLR) => {
    const { get, isLoading, hideLoading } = useRequest(currentEndPointPath);


    const authHeimdallr = async () => {
        const endpoint = `logon`;
        const result = await get(endpoint, {message: "Autenticando su cuenta", autohide: true});       
        const { data, message, statusCode }: ResponseObject = result.response;
        // console.log("response auth", result);
        
        if ([200, 201].includes(statusCode)) {
            const payload:LoginPayloadResponse = {
                token: data.token || null,
                data: data || null,
                statusCode: statusCode,
                message: data.message,
                operationStatus: isSuccessCode(statusCode)
            }
            return payload;
        } else {
            const payload:LoginPayloadResponse = {
                token: undefined,
                statusCode: statusCode,
                message: message,
                operationStatus: false
            }
            hideLoading()
            return payload;
        }
    }

    const getCoworkers = async () => {
        const endpoint = `coworkers/catalog`;
        const result = await get(endpoint, {message: "Obteniendo los usuarios registrados", autohide: true});       
        const { data, message, statusCode }: ResponseObject = result.response;
        // console.log("response coworkers", result);
        
        if ([200, 201].includes(statusCode)) {
            const payload:LoginPayloadResponse = {
                token: data.token || null,
                data: data || null,
                statusCode: statusCode,
                message: data.message,
                operationStatus: isSuccessCode(statusCode)
            }
            return payload;
        } else {
            const payload:LoginPayloadResponse = {
                token: undefined,
                statusCode: statusCode,
                message: message,
                operationStatus: false
            }
            hideLoading()
            return payload;
        }
    }

    const getReport = async (codebar: string, month: number, period: number, year: number) => {
        const endpoint = `attendance/report/${codebar}/${month}/${period}/${year}`;
        const result = await get(endpoint, {message: "Obteniendo el reporte", autohide: true});       
        const { data, message, statusCode }: ResponseObject = result.response;
        // console.log("report coworkers", result);
        
        if ([200, 201].includes(statusCode)) {
            const payload:LoginPayloadResponse = {
                token: data.token || null,
                data: data || null,
                statusCode: statusCode,
                message: data.message,
                operationStatus: isSuccessCode(statusCode)
            }
            return payload;
        } else {
            const payload:LoginPayloadResponse = {
                token: undefined,
                statusCode: statusCode,
                message: message,
                operationStatus: false
            }
            hideLoading()
            return payload;
        }
    }

    return {
        isLoading,
        getCoworkers,
        getReport,
        authHeimdallr
    }
}

export default useAttendanceApiService;