// Importar todos los íconos
import AcademicIconImage from "@assets/icons/svg/academic.svg";
import ActivitiesIconImage from "@assets/icons/svg/activities.svg";
import ArchiveIconImage from "@assets/icons/svg/archive.svg";
import CalculateIconImage from "@assets/icons/svg/calculate.svg";
import CalendarIconImage from "@assets/icons/svg/calendar.svg";
import CareerIconImage from "@assets/icons/svg/career.svg";
import CheckIconImage from "@assets/icons/svg/check.svg";
import ClasslistIconImage from "@assets/icons/svg/classlist.svg";
import CourseIconImage from "@assets/icons/svg/course.svg";
import DeleteIconImage from "@assets/icons/svg/delete.svg";
import DownloadIconImage from "@assets/icons/svg/download.svg";
import DraftIconImage from "@assets/icons/svg/draft.svg";
import DropdownArrowIconImage from "@assets/icons/svg/dropdown-arrow.svg";
import EditIconImage from "@assets/icons/svg/edit.svg";
import FailIconImage from "@assets/icons/svg/fail.svg";
import FinishIconImage from "@assets/icons/svg/finish.svg";
import GhostIconImage from "@assets/icons/svg/ghost.svg";
import GradesIconImage from "@assets/icons/svg/grades.svg";
import ImageIconImage from "@assets/icons/svg/image.svg";
import KeyIconImage from "@assets/icons/svg/key.svg";
import LockIconImage from "@assets/icons/svg/lock.svg";
import LogoutIconImage from "@assets/icons/svg/logout.svg";
import MedalIconImage from "@assets/icons/svg/medal.svg";
import Medal2IconImage from "@assets/icons/svg/medal2.svg";
import MicrophoneIconImage from "@assets/icons/svg/microphone.svg";
import PhoneIconImage from "@assets/icons/svg/phone.svg";
import ReportIconImage from "@assets/icons/svg/report.svg";
import SadIconImage from "@assets/icons/svg/sad.svg";
import SearchIconImage from "@assets/icons/svg/search.svg";
import ShareIconImage from "@assets/icons/svg/share.svg";
import SignoutIconImage from "@assets/icons/svg/signout.svg";
import StudentsIconImage from "@assets/icons/svg/students.svg";
import TashbinIconImage from "@assets/icons/svg/tashbin.svg";
import ThumbIconImage from "@assets/icons/svg/thumb.svg";
import TileIconImage from "@assets/icons/svg/tile.svg";
import UndoIconImage from "@assets/icons/svg/undo.svg";
import UserIconImage from "@assets/icons/svg/user.svg";
import WorkblockIconImage from "@assets/icons/svg/workblock.svg";
import XlsxIconImage from "@assets/icons/svg/xlsx.svg";
import SVGIcon from "@components/SVGIcon";

interface IconProperties {
    size: number;
    className?: string;
    classNameBefore?: string;
    styleBefore?: string;
}

// Crear componentes de iconos
const AcademicIcon = (props: IconProperties) => <SVGIcon src={AcademicIconImage} {...props} />;
const ActivitiesIcon = (props: IconProperties) => <SVGIcon src={ActivitiesIconImage} {...props} />;
const ArchiveIcon = (props: IconProperties) => <SVGIcon src={ArchiveIconImage} {...props} />;
const CalculateIcon = (props: IconProperties) => <SVGIcon src={CalculateIconImage} {...props} />;
const CalendarIcon = (props: IconProperties) => <SVGIcon src={CalendarIconImage} {...props} />;
const CareerIcon = (props: IconProperties) => <SVGIcon src={CareerIconImage} {...props} />;
const CheckIcon = (props: IconProperties) => <SVGIcon src={CheckIconImage} {...props} />;
const ClasslistIcon = (props: IconProperties) => <SVGIcon src={ClasslistIconImage} {...props} />;
const CourseIcon = (props: IconProperties) => <SVGIcon src={CourseIconImage} {...props} />;
const DeleteIcon = (props: IconProperties) => <SVGIcon src={DeleteIconImage} {...props} />;
const DownloadIcon = (props: IconProperties) => <SVGIcon src={DownloadIconImage} {...props} />;
const DraftIcon = (props: IconProperties) => <SVGIcon src={DraftIconImage} {...props} />;
const DropdownArrowIcon = (props: IconProperties) => <SVGIcon src={DropdownArrowIconImage} {...props} />;
const EditIcon = (props: IconProperties) => <SVGIcon src={EditIconImage} {...props} />;
const FailIcon = (props: IconProperties) => <SVGIcon src={FailIconImage} {...props} />;
const FinishIcon = (props: IconProperties) => <SVGIcon src={FinishIconImage} {...props} />;
const GhostIcon = (props: IconProperties) => <SVGIcon src={GhostIconImage} {...props} />;
const GradesIcon = (props: IconProperties) => <SVGIcon src={GradesIconImage} {...props} />;
const ImageIcon = (props: IconProperties) => <SVGIcon src={ImageIconImage} {...props} />;
const KeyIcon = (props: IconProperties) => <SVGIcon src={KeyIconImage} {...props} />;
const LockIcon = (props: IconProperties) => <SVGIcon src={LockIconImage} {...props} />;
const LogoutIcon = (props: IconProperties) => <SVGIcon src={LogoutIconImage} {...props} />;
const MedalIcon = (props: IconProperties) => <SVGIcon src={MedalIconImage} {...props} />;
const Medal2Icon = (props: IconProperties) => <SVGIcon src={Medal2IconImage} {...props} />;
const MicrophoneIcon = (props: IconProperties) => <SVGIcon src={MicrophoneIconImage} {...props} />;
const PhoneIcon = (props: IconProperties) => <SVGIcon src={PhoneIconImage} {...props} />;
const ReportIcon = (props: IconProperties) => <SVGIcon src={ReportIconImage} {...props} />;
const SadIcon = (props: IconProperties) => <SVGIcon src={SadIconImage} {...props} />;
const SearchIcon = (props: IconProperties) => <SVGIcon src={SearchIconImage} {...props} />;
const ShareIcon = (props: IconProperties) => <SVGIcon src={ShareIconImage} {...props} />;
const SignoutIcon = (props: IconProperties) => <SVGIcon src={SignoutIconImage} {...props} />;
const StudentsIcon = (props: IconProperties) => <SVGIcon src={StudentsIconImage} {...props} />;
const TashbinIcon = (props: IconProperties) => <SVGIcon src={TashbinIconImage} {...props} />;
const ThumbIcon = (props: IconProperties) => <SVGIcon src={ThumbIconImage} {...props} />;
const TileIcon = (props: IconProperties) => <SVGIcon src={TileIconImage} {...props} />;
const UndoIcon = (props: IconProperties) => <SVGIcon src={UndoIconImage} {...props} />;
const UserIcon = (props: IconProperties) => <SVGIcon src={UserIconImage} {...props} />;
const WorkblockIcon = (props: IconProperties) => <SVGIcon src={WorkblockIconImage} {...props} />;
const XlsxIcon = (props: IconProperties) => <SVGIcon src={XlsxIconImage} {...props} />;

// Exportar todos los íconos
export {
    AcademicIcon,
    ActivitiesIcon,
    ArchiveIcon,
    CalculateIcon,
    CalendarIcon,
    CareerIcon,
    CheckIcon,
    ClasslistIcon,
    CourseIcon,
    DeleteIcon,
    DownloadIcon,
    DraftIcon,
    DropdownArrowIcon,
    EditIcon,
    FailIcon,
    FinishIcon,
    GhostIcon,
    GradesIcon,
    ImageIcon,
    KeyIcon,
    LockIcon,
    LogoutIcon,
    MedalIcon,
    Medal2Icon,
    MicrophoneIcon,
    PhoneIcon,
    ReportIcon,
    SadIcon,
    SearchIcon,
    ShareIcon,
    SignoutIcon,
    StudentsIcon,
    TashbinIcon,
    ThumbIcon,
    TileIcon,
    UndoIcon,
    UserIcon,
    WorkblockIcon,
    XlsxIcon
};
